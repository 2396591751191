import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { textlinkColor, textlinkColorDarkened } from 'helpers/themehelpers';
import { gapable, fontSizable, displayable } from 'helpers/traits';
import LinkWrapper from './link-wrapper';

/** Wrapper */
const Wrapper = styled(LinkWrapper)`
    color: ${textlinkColor};
    ${gapable()};
    ${fontSizable()};
    ${displayable('inline-block')};
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: ${textlinkColorDarkened};
    }

    ${({ noDecoration }) =>
        !noDecoration &&
        `
        &:hover {
            text-decoration: underline;
        }
    `};

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${textlinkColor};
        `};
`;

/**
 * Erzeugt einen Textlink
 * @param {string} props.className Optional: Klasse für erweitertest Styling
 * @param {string} props.children Der Inhalt des Buttons (zumeist Text)
 * @param {string} props.to: Link des Buttons (kann intern, extern, Anker sein)
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.display Optional: Display-Einstellung
 * @param {string} props.fontSize Optional: Die Schriftgröße ('s', 'm', 'l' ...)
 * @param {string} props.textColor Optional: Schriftfarbe
 * @param {bool} props.noDecoration Optional: Ob Link Text-Dekoration bei Hover angezeigt wird
 * @param {bool} props.isActive Optional: Aktivzustand an / aus
 * @param {number} props.scrollOffset Optional: Offset für Ankerlinks
 *
 *
 * @example <Textlink gap="l" size="s">Test</Textlink>
 */
const Textlink = ({
    className,
    to,
    children,
    gap,
    display,
    fontSize,
    textColor,
    noDecoration,
    isActive,
    scrollOffset,
}) => (
    <Wrapper
        to={to}
        className={className}
        gap={gap}
        display={display}
        fontSize={fontSize}
        textColor={textColor}
        noDecoration={noDecoration}
        isActive={isActive}
        scrollOffset={scrollOffset}
    >
        {children}
    </Wrapper>
);

Textlink.propTypes = {
    gap: gapable.propType,
    fontSize: PropTypes.string,
    display: displayable.propType,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    to: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    noDecoration: PropTypes.bool,
    scrollOffset: PropTypes.number,
    isActive: PropTypes.bool,
};

Textlink.defaultProps = {
    className: null,
    fontSize: 's',
    display: null,
    gap: null,
    textColor: null,
    noDecoration: null,
    scrollOffset: 0,
    isActive: null,
};

export default Textlink;
